.limit-media {
    &--overlay {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 1;
        span {
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            position: absolute;
        }
    }
    &--wrap {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
