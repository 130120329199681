@media only screen and (min-width: 500px) {
  .page-main {
    justify-content: center;
    .page-content {
      width: 100%;
    }
  }
}

@media only screen and (min-width: 1025px) {
  .page-main {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    .page-content {
      margin-left: 10vw;
      margin-right: 10vw;
    }
  }

}