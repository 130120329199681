.dashboard-container {
    height: 100%;
    width: 100%;
    vertical-align: middle;
    margin-bottom: 16px;
    & > .dashboard-wrap {
        width: 100%;
        height: 100%;
        margin: auto;
        display: flex;

        .dashboard-header {
            width: 100%;

            &.pricing-message {
                .Polaris-LegacyCard {
                    background-color: #e5eeeb;
                }
                .Polaris-CalloutCard__Image.Polaris-CalloutCard__DismissImage {
                    width: 100%;
                    height: 100%;
                    max-width: 192px;
                    max-height: 124px;
                    @media (max-width: 768px) {
                        width: 150px;
                    }
                }
            }
        }
        .dashboard-middle {
            width: 100%;
            .statistics-block {
                border: 1px solid #e3e3e3;
                border-radius: 8px;
                padding: 10px 9px;
                background: #ffffff;
            }
        }
        .dashboard-footer {
            width: 100%;
        }
        .dashboard-whats-new {
            width: 100%;
            overflow: hidden;
            max-width: 950px;
            .slide-navigation {
                position: relative;
                width: fit-content;
                box-sizing: content-box;
                padding: 4px 6px;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                gap: 4px;
                border-radius: 8px;
                background-color: #e3e3e3;
                .slide-button {
                    border: none;
                    background: transparent;
                    padding: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    width: 24px;
                    height: 24px;
                    &:hover {
                        opacity: 0.7;
                    }
                }
            }
            .slides-container {
                width: 100%;
                overflow: hidden;
                position: relative;
                .slides-wrapper {
                    display: flex;
                    transition: transform 0.5s ease-in-out;
                    gap: 20px;
                    .slide-box {
                        flex: 0 0 90%;
                        display: flex;
                        align-items: stretch;
                        justify-content: space-between;
                        gap: 47px;
                        border: 1px solid #e3e3e3;
                        padding: 16px;
                        border-radius: 8px;
                        background-color: #ffffff;
                        min-height: 280px;
                        .slide-content {
                            flex: 1;
                            display: flex;
                            flex-direction: column;
                            gap: 9px;
                            .headingMd {
                                font-size: 18px;
                                font-weight: bold;
                            }
                            .bodySm {
                                color: #303030;
                                font-size: 13px;
                                line-height: 30px;
                            }
                            ul {
                                padding-left: 20px;
                                margin: 0;
                                li {
                                    font-size: 13px;
                                    line-height: 1.4;
                                }
                            }
                        }
                        .slide-image {
                            flex: 1;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            img {
                                max-width: 100%;
                                height: auto;
                                object-fit: contain;
                                border-radius: 8px;
                            }
                        }
                    }
                }
            }
            .statistic-divider {
                display: block;
                width: 1px;
                background-color: #b3b3b3;
                @media (max-width: 420px) {
                    display: none;
                }

                @media (min-width: 422px) and (max-width: 438px) {
                    &.divider-2 {
                        display: none;
                    }
                    &.divider-4 {
                        display: none;
                    }
                }

                @media (min-width: 605px) and (max-width: 621px) {
                    &.divider-3 {
                        display: none;
                    }
                }

                @media (min-width: 771px) and (max-width: 787px) {
                    &.divider-4 {
                        display: none;
                    }
                }
            }
        }
        .dashboard-block-support {
            width: 100%;

            &.pricing-message {
                .Polaris-LegacyCard {
                    background-color: #e5eeeb;
                }
                .Polaris-CalloutCard__Image.Polaris-CalloutCard__DismissImage {
                    width: 100%;
                    height: 100%;
                    max-width: 192px;
                    max-height: 124px;
                    @media (max-width: 768px) {
                        width: 150px;
                    }
                }
            }
        }
    }
}

.image-container {
    position: absolute;
    right: 0px;
    top: 35px;
    display: flex;
    align-items: center;

    img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        object-fit: cover;
        border: 1px solid #ffffff;
        position: relative;
    }
    .image-1,
    .image-2,
    .image-3 {
        clip-path: none;
        &.image-1 {
            transform: translateX(5px);
            clip-path: inset(0 10% 0 0);
        }
        &.image-2 {
            transform: translateX(-5px);
            clip-path: inset(0 15% 0 0);
        }
        &.image-3 {
            transform: translateX(-15px);
        }
    }

    .Polaris-Icon {
        margin: 0 !important;
    }

    .Polaris-CalloutCard--hasDismiss {
        padding-right: 0 !important;
    }

    .Polaris-Text--headingSm {
        font-size: 13px !important;
        font-weight: 550 !important;
    }
}

.select-language--container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.select-language {
    position: relative;
    width: fit-content;
    box-sizing: content-box;
    padding: 6px 8px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 4px;
    border-radius: 8px;
    border: 1px solid #e3e3e3;
    background-color: #ffffff;
    cursor: pointer;
    .selected-lng {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        font-size: 12px;
        font-weight: 550;
        line-height: 16px;
        color: #303030;
        span {
            svg {
                path {
                    fill: #303030;
                }
            }
        }
    }
    .list-language--wrap {
        position: absolute;
        min-width: 200px;
        bottom: unset;
        top: 100%;
        z-index: 150;
        right: 0;
        background-color: #ffffff;
        // display: none;
        flex-direction: column;
        gap: 4px;
        padding: 6px;
        border-radius: 12px;
        box-shadow: 0px 4px 6px -2px rgba(26, 26, 26, 0.2),
            0px 1px 0px 0px rgba(204, 204, 204, 0.5) inset,
            0px -1px 0px 0px rgba(0, 0, 0, 0.17) inset,
            -1px 0px 0px 0px rgba(0, 0, 0, 0.13) inset,
            1px 0px 0px 0px rgba(0, 0, 0, 0.13) inset;
        .list-language {
            max-height: 250px;
            overflow-y: auto;

            display: flex;
            flex-direction: column;
            gap: 4px;

            &::-webkit-scrollbar {
                width: 4px !important;
            }
            &::-webkit-scrollbar-thumb:hover {
                background: #888 !important;
            }
            &::-webkit-scrollbar-thumb {
                background-color: #999 !important;
                border-radius: 10px !important;
            }

            &::-webkit-scrollbar-track {
                background-color: #f1f1f1 !important;
            }
            .language-item {
                font-size: 13px;
                line-height: 20px;
                color: #303030;
                font-weight: 450;
                padding: 6px;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: space-between;
                cursor: pointer;
                margin-right: 5px;
                &.selected-language,
                &:hover {
                    font-weight: 650;
                    background-color: #ebebeb;
                    border-radius: 8px;
                }
                .Polaris-Icon {
                    margin: 0;
                }
            }
        }
        &::before {
            content: "";
            position: absolute;
            top: -8px;
            right: 0;
            left: 0;
            width: 100%;
            background-color: transparent;
            height: 8px;
        }
    }
    // &:hover {
    //     .list-language--wrap {
    //         display: flex;
    //     }
    // }
}

.add-script--banner {
    width: 100%;
}

.modal-add-script {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
    background-color: rgba(0, 0, 0, 0.4);
    .modal-wrap {
        background-color: white;
        border-radius: 12px;
        min-width: 60%;
        height: fit-content;
        box-shadow: 0px 20px 20px -8px #1a1a1a47,
            0px 1px 0px 0px #cccccc80 inset, 0px -1px 0px 0px #0000002b inset,
            -1px 0px 0px 0px #0000002b inset, 1px 0px 0px 0px #00000021 inset;
        .modal-add-script--header {
            background-color: #f3f3f3;
            padding: 12px;
            border-radius: 12px 12px 0px 0px;
        }
        .modal-add-script--content {
            padding: 8px;
        }

        .add-widget--helptext {
            text-align: center;
            text-decoration: underline;
            cursor: pointer;
            color: #005bd3;
            size: 13px;
            font-weight: 450;
            &:hover {
                color: #0069f1;
            }
        }

        @media (max-width: 500px) {
            height: fit-content !important;
            .right {
                display: none;
            }
        }
    }

    @media (max-width: 900px) {
        overflow: auto;
        padding: 50px 10px;
        .modal-wrap {
            overflow: auto;
            width: 80%;
            max-width: unset;
            &::-webkit-scrollbar {
                display: none;
            }
            .add-block-script--guide {
                .left {
                    max-width: 500px !important;
                }
                .right {
                    max-width: 500px !important;
                }
            }
        }
    }
}

#not-good--modal {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 0px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00000080;
    z-index: 99999;
    #not-good-modal--wrap {
        width: 100%;
        max-width: 620px;
    }
}

@media (max-width: 768px) {
    .dashboard-block-support .image-container {
        display: none;
    }
    .select-language--container {
        padding: 16px 16px 0px 16px;
    }
}

@media (max-width: 768px) {
    .slides-wrapper {
        // display: grid !important;
        max-width: 390px !important;
        grid-template-columns: 1fr;
        gap: 20px;
    }
}

@media (max-width: 768px) {
    // .slide-navigation,
    .slide-image {
        display: none !important;
    }
}

@media (max-width: 1024px) {
    .slides-wrapper {
        max-width: 700px;
    }
}

@media (max-width: 844px) {
    .dashboard-block-support .image-container {
        display: none;
    }
    .select-language--container {
        padding: 16px 16px 0px 16px;
    }
    .slides-wrapper {
        max-width: 300px !important;
        grid-template-columns: 1fr;
        gap: 16px;
    }
    .slide-image {
        display: none !important;
    }
}

@media (max-width: 1024px) {
    .slides-wrapper {
        max-width: 700px;
    }
}
