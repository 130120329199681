.review-management-header--sticky {
    position: sticky;
    top: 0;
    background-color: #f1f1f1;
    z-index: 517;
}

.table-list--review {
    height: 100%;
    background-color: white;
    border-radius: 12px;
    border: 1px solid var(--p-color-border);
    overflow: clip;
    .Polaris-BulkActions__ButtonGroupWrapper {
        background-color: #f6f6f6;
        .Polaris-InlineStack {
            .Polaris-BulkActions__BulkActionButton {
                &:first-child {
                    button.Polaris-Button.Polaris-Button--sizeSlim {
                        background-color: var(--p-color-bg-fill);
                        box-shadow: 0px 1px 0px 0px #e3e3e3 inset,
                            1px 0px 0px 0px #e3e3e3 inset,
                            -1px 0px 0px 0px #e3e3e3 inset,
                            0px -1px 0px 0px #b5b5b5 inset;
                        span {
                            color: var(--p-color-text-critical);
                        }
                        &:hover {
                            background: var(--p-color-bg-surface-hover);
                            outline: var(--p-border-width-025) solid transparent;
                            box-shadow: var(
                                --pc-button-shadow-hover
                            ) !important;
                        }
                    }
                }
                &:nth-child(2) {
                    button.Polaris-Button.Polaris-Button--sizeSlim {
                        background-color: var(--p-color-bg-fill);
                        box-shadow: 0px 1px 0px 0px #e3e3e3 inset,
                            1px 0px 0px 0px #e3e3e3 inset,
                            -1px 0px 0px 0px #e3e3e3 inset,
                            0px -1px 0px 0px #b5b5b5 inset;
                        span {
                            color: var(--p-color-text-critical);
                        }
                        &:hover {
                            background: var(--p-color-bg-surface-hover);
                            outline: var(--p-border-width-025) solid transparent;
                            box-shadow: var(
                                --pc-button-shadow-hover
                            ) !important;
                        }
                    }
                }
                &:nth-child(3) {
                    button.Polaris-Button.Polaris-Button--sizeSlim {
                        background-color: var(--p-color-bg-fill-brand);
                        box-shadow: 0px 1px 0px 0px #e3e3e3 inset,
                            1px 0px 0px 0px #e3e3e3 inset,
                            -1px 0px 0px 0px #e3e3e3 inset,
                            0px -1px 0px 0px #b5b5b5 inset;
                        span {
                            color: var(--p-color-text-brand-on-bg-fill);
                        }
                        &:hover {
                            span {
                                color: var(
                                    --p-color-text-brand-on-bg-fill-hover
                                );
                            }

                            background: var(--p-color-bg-fill-brand-hover);
                            border-color: transparent;
                        }
                    }
                }
                &:last-child {
                    button.Polaris-Button.Polaris-Button--sizeSlim {
                        background-color: var(
                            --p-color-bg-fill-brand
                        ) !important;
                        box-shadow: 0px 1px 0px 0px #e3e3e3 inset,
                            1px 0px 0px 0px #e3e3e3 inset,
                            -1px 0px 0px 0px #e3e3e3 inset,
                            0px -1px 0px 0px #b5b5b5 inset;
                        span {
                            color: var(
                                --p-color-text-brand-on-bg-fill
                            ) !important;
                        }
                        &:hover {
                            span {
                                color: var(
                                    --p-color-text-brand-on-bg-fill-hover
                                ) !important;
                            }

                            background: var(
                                --p-color-bg-fill-brand-hover
                            ) !important;
                            border-color: transparent !important;
                        }
                    }
                }
            }
        }
    }

    .modal-import--review {
        width: 80%;
        max-width: 622px;
        height: fit-content;
        background-color: #ffffff;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 16px;
        @media (max-width: 490px) {
            height: fit-content;
            .Polaris-DropZone {
                min-height: 200px !important;
            }
        }
        .modal-import-review--header {
            .close-modal--icon {
                cursor: pointer;
                &:hover {
                    svg {
                        path {
                            fill: #030303;
                        }
                    }
                }
            }
        }
        .modal-import-review--body {
            .Polaris-DropZone {
                min-height: 120px;
                &.Polaris-DropZone--hasError {
                    background-color: #fee9e8;
                    &::after {
                        border-color: #ef4d2f;
                    }
                }
                .import--field {
                    height: 100%;
                    display: grid;
                    place-content: center;
                }
            }
            .Polaris-Icon {
                margin: 0;
            }
        }
    }

    .suggestion {
        display: flex;
        padding: 5px 5px;
        flex-direction: column;
        border-radius: 4px;
        background-color: rgb(239, 241, 241);
    }

    .suggestion-title {
        font-weight: 700;
    }

    .suggestion-content {
        display: flex;
        flex-direction: column;
        padding-top: 5px;
    }

    .suggestion-item {
        padding: 0px 5px;
    }

    .sample-link {
        color: rgb(0, 127, 255);
    }

    .ispid-content {
        display: flex;
        flex-direction: column;
    }

    .ispid-link {
        color: rgb(0, 127, 255);
        text-decoration: none;
    }

    .ispid-item {
        padding-top: 5px;
    }

    .review-management-date--filter {
        & > div > button.Polaris-Button {
            box-shadow: none;
            border: 1px dashed var(--p-color-border);
            padding: 1px 8px 1px 12px;
            box-sizing: border-box;
            height: 26px;
            min-height: unset;
            span {
                line-height: 100%;
            }
            &:hover {
                background-color: transparent;
            }
        }
    }
    .Polaris-AlphaTabs__Outer {
        & > .Polaris-Box {
            padding-top: 0;
            padding-bottom: 0;
            border-bottom: 0;
        }
    }
    .Polaris-AlphaTabs__Tab {
        padding-top: 8px;
        padding-bottom: 8px;
        height: 47px;
        &--active {
            background: none;
            border-bottom: 3px solid rgba(0, 122, 92, 1);
            border-radius: 0;
            .Polaris-Text--root {
                font-weight: 700;
                color: #000;
            }
        }
    }
    .Polaris-IndexTable {
        .Polaris-IndexTable__Table th {
            border-top: 1px solid rgb(225 227 229);
        }
    }
    .Polaris-IndexTable__TableRow {
        td {
            padding-top: 20px;
            padding-bottom: 10px;
            vertical-align: top;
            &.Polaris-IndexTable__TableCell--first {
                padding-top: 25px !important;
                vertical-align: top;
            }
        }
        .item-table--row {
            &.item-table--review {
                padding: 16px 20px 16px 0;
                width: 60%;
                max-width: 650px;
                min-width: 500px;
                .review-content {
                    display: flex;
                    flex-direction: column;
                    gap: 6px;
                    h4 {
                        cursor: pointer;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                    p {
                        cursor: pointer;
                    }
                }
            }
        }
        &--hovered,
        &--hovered td {
            cursor: default;
            background-color: #fff !important;
        }
    }
}

.item-table {
    &--product {
        white-space: normal;
        max-width: 400px;
        .item-product-wrapper {
            display: flex;
            align-items: center;
            cursor: pointer;
            &:hover {
                h3 {
                    text-decoration: underline;
                }
            }
        }
        .thumb-product-default {
            border: 1px solid #c9cccf;
            border-radius: 4px;
            width: 40px;
            height: 40px;
            svg {
                width: 38px;
                height: 38px;
            }
        }
        h3 {
            font-size: 14px;
        }
    }
    &--review {
        white-space: pre-wrap;
        width: 50%;
        h4 {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 10px;
            word-break: break-word;
        }
        p {
            word-break: break-word;
        }
        .show-more--btn {
            color: #999;
            text-decoration: none;
        }
        .bottom {
            display: flex;
            justify-content: space-between;
            margin-top: 15px;
            .time {
                font-size: 12px;
                color: rgba(32, 34, 35, 0.5);
            }
            .box-user {
                color: #202223;
                font-size: 12px;
                display: flex;
                align-items: center;
                font-weight: 500;
                .user-info {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    span {
                        line-height: 100%;
                        &:first-child {
                            font-size: 12px;
                            font-weight: 700;
                        }
                        &:last-child {
                            font-size: 9px;
                            font-weight: 400;
                            color: #909191;
                        }
                    }
                }
                .avatar {
                    background: #91e0d6;
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    font-size: 10px;
                    text-transform: uppercase;
                    text-align: center;
                    line-height: 20px;
                    display: block;
                    margin-left: 5px;
                }
            }
        }

        .review-wrapper {
            .review-badge {
                .Polaris-Badge {
                    padding: 2px 3px;
                }
            }
        }
    }
    &--rating {
        padding-left: 30px;
        vertical-align: top;
        padding-top: 22px;
    }
    &--action {
        display: flex;
        flex-direction: row;
        gap: 6px;
        flex-wrap: wrap;
        width: 165px;
        .top,
        .right {
            display: flex;
            button {
                cursor: pointer;
            }
        }
        .top {
            margin-bottom: 10px;
        }
        .btn-publish {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 8px 16px 8px 30px;
            background: #008060;
            box-shadow: 0 1px 0 rgba(0, 0, 0, 0.08),
                inset 0px -1px 0px rgba(0, 0, 0, 0.2);
            border-radius: 4px;
            height: 36px;
            color: #fff;
            border: 0;
            cursor: pointer;
            font-weight: 500;
            background-image: url("../images/up-icon.svg");
            background-repeat: no-repeat;
            background-position: 10px 12px;
            margin-right: 10px;
        }
        .btn-reply {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 8px 16px 8px 32px;
            background: rgba(255, 255, 255, 0.01);
            border: 1px solid #abb1ba;
            border-radius: 4px;
            height: 36px;
            color: #202223;
            cursor: pointer;
            font-weight: 500;
            background-image: url("../images/reply-icon.svg");
            background-repeat: no-repeat;
            background-position: 10px 10px;
            margin-right: 10px;
        }
        .btn-unpublish {
            padding: 8px 16px;
            background: rgba(255, 255, 255, 0.01);
            border: 1px solid #abb1ba;
            border-radius: 4px;
            height: 36px;
            color: #202223;
            cursor: pointer;
            font-weight: 500;
            background-repeat: no-repeat;
            background-position: 10px 10px;
            margin-right: 10px;
        }
        .btn-remove {
            padding: 0 10px;
            gap: 10px;
            height: 36px;
            background: #fff;
            border: 1px solid #abb1ba;
            box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
            border-radius: 4px;
            font-weight: 500;
            font-size: 24px;
            line-height: 30px;
            text-align: center;
            color: #d72c0d;
            display: flex;
        }
        .btn-reject {
            background-color: #fff0f0;
            background-image: url("../images/icon-reject.svg");
            background-repeat: no-repeat;
            background-position: 10px 13px;
            padding: 8px 16px 8px 32px;
            gap: 10px;
            height: 36px;
            border: 1px solid #d82c0d;
            box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
            border-radius: 4px;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: #d72c0d;
            display: flex;
        }
    }
}
.box-rating {
    .number-rating {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 5px;
    }
    .star-rate {
        display: flex;
    }
    .star-full {
        width: 15px;
        height: 15px;
        background-image: url("../images/Star.svg");
        display: block;
        background-repeat: no-repeat;
        margin-right: 5px;
    }
    .star-empty {
        width: 15px;
        height: 15px;
        background-image: url("../images/star-empty.svg");
        display: block;
        background-repeat: no-repeat;
        margin-right: 5px;
    }
}
.pagi {
    padding: 10px 0 5px;
    .Polaris-ButtonGroup--segmented {
        justify-content: center;
    }
}

#PolarisPortalsContainer .Polaris-Popover__Pane {
    .Polaris-Box:has(> .Polaris-SortButton-DirectionButton) {
        display: none;
    }
}

.Polaris-Tabs__Tab {
    .count {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        padding: 2px 8px;
        gap: 4px;
        background: #e4e5e7;
        border-radius: 10px;
        margin-left: 3px;
    }
}
.thumbnail-list {
    display: flex;
    flex-direction: row;
    gap: 6px;
    .thumb-item {
        width: 46px;
        height: 46px;
        border: 1px solid #c9cccf;
        border-radius: 8px;
        overflow: hidden;
        cursor: pointer;
        position: relative;
        img {
            width: 100%;
            height: 100%;
            object-fit: fill;
        }
        .thumb-item-video--overlay {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgba(0, 0, 0, 0.2);
            z-index: 1;
        }
    }
    .thumbnail-count {
        background: #fafbfb;
        border: 1px solid #e1e2e4;
        border-radius: 3px;
        width: 46px;
        height: 46px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: rgba(32, 34, 35, 0.5);
        cursor: pointer;
    }
}

// .form-reply {
//     margin-top: 15px;
//     .title {
//         color: #202223;
//         font-size: 14px;
//         font-weight: bold;
//         margin-bottom: 10px;
//     }
// }
.form-reply,
.box-reply--wrap {
    .box-reply {
        background: #fdfdfd;
        border-radius: 8px;
        padding: 12px;
        border: 1px solid var(--p-color-border);
        textarea {
            width: 100%;
            min-height: 56px;
            resize: none;
            border: 0;
            border-radius: 4px;
            outline: 0 !important;
            font-size: 13px;
            line-height: 20px;
            font-family: "Inter", sans-serif;
            &:focus {
                outline: 2px solid #458fff;
            }
        }
        button {
            z-index: 500;
            height: 28px;
            min-height: 28px;
            border-radius: 4px;
            padding: 0 12px;
            font-size: 14px;
            font-weight: 500;
            cursor: pointer;
            &.Polaris-Button--disabled {
                background: #f1f1f1;
                border: 1px solid transparent;
                color: #8c9196;
            }
            &.reply-cancel {
                background: #f1f1f1;
                border-radius: 4px;
                padding: 4px 12px;
                right: 80px;
                display: flex;
                align-items: center;
                border: 0;
                color: #8c9196;
                font-size: 14px;
                margin-right: 10px;
            }
        }
        &.box-reply-edit {
            button {
                &.reply-cancel {
                    margin-left: 10px;
                }
            }
        }
    }
}
.reply-box {
    padding-left: 10px;
    h5 {
        color: #202223;
        font-size: 14px;
        font-weight: 600;
    }
    .reply-content {
        p {
            button {
                cursor: pointer;
                display: inline-block;
                line-height: 1.25rem;
                padding: 0;
                border: 0;
                background: none;
                color: #20222380;
            }
        }
    }
}
.lightbox-image {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #202020;
    z-index: 999999;
    color: #fff;
    overflow: hidden;
    &-header {
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 30px;
        border-bottom: 2px solid #000;
        &--right {
            display: flex;
            button,
            a {
                background: none;
                border: 0;
                cursor: pointer;
                margin-left: 20px;
                height: 25px;
                img {
                    width: 20px;
                    height: 20px;
                }
                &:nth-child(2) {
                    img {
                        width: 24px;
                        height: 24px;
                    }
                }
            }
        }
    }
    &-body {
        display: flex;
        &--left {
            width: 100%;
            max-width: 75%;
            background: #252629;
            min-height: 100vh;
        }
        &--right {
            padding-top: 30px;
            padding-left: 30px;
            color: #d6d6d6;
            h3 {
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                margin-bottom: 20px;
            }
        }
    }
}

.slider-img {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90vh;
    padding: 40px 80px;
    position: relative;
    overflow: hidden;
    .slide-item {
        object-fit: cover;
        display: none;
        max-height: 100%;
        max-width: 100%;
        opacity: 0;
        transition: opacity 1s ease-in-out;
        &.active {
            display: block;
            opacity: 1;
        }
    }
    iframe {
        width: 100%;
        height: 100%;
    }
    .pagi {
        .prev,
        .next {
            position: absolute;
            top: 50%;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            cursor: pointer;
        }
        .prev {
            left: 20px;
            background: #000 url("../images/icon-prev.svg") no-repeat center;
        }
        .next {
            background: #000 url("../images/icon-next.svg") no-repeat center;
            right: 20px;
        }
    }
}

.tab-review-action {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    gap: 10px;
}

.all-media-page {
    &--wrap{
        padding-bottom: 16px;
    }
    &--container {
    }

    &--wrap {
        display: grid;
        grid-template-columns: 2fr 1fr;
        gap: 16px;
        @media (max-width: 768px) {
            position: relative;
            grid-template-columns: 1fr;
        }
    }

    &__secondary-section {
        width: 312px;
        height: calc(98vh - 76px);
        overflow: scroll;
        scrollbar-width: none;
        position: relative;
        #all-media-page__secondary-section-close--btn {
            display: none;
            position: absolute;
            right: 6px;
            top: 6px;
            z-index: 9999;
        }
        &--wrap {
            height: fit-content;
            max-height: 100%;
        }

        #all-media-page__empty-media-detail {
            max-width: 198px;
            button {
                flex-direction: row-reverse;
                span {
                    margin: 0;
                    display: flex;
                    height: 100%;
                }
            }
        }

        @media (max-width: 768px) {
            width: 100%;
            position: absolute;
            z-index: 999;
            background-color: #ffffff;
            border-radius: 12px;
            
            &.hide-section {
                display: none;
            }

            #all-media-page__secondary-section-close--btn {
                display: block;
            }
            .review-detail-img--container {
                .review-detail-img--wrap {
                    min-width: 150px !important;
                    max-width: 300px !important;
                    margin: auto;
                }
            }
        }
    }
    &__review-detail--container {
        position: relative;
        .review-detail-img--container {
            position: relative;
            .review-detail-img--wrap {
                width: 100%;
                max-width: 280px;
                aspect-ratio: 1/1;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 8px;
                overflow: hidden;
                border: 1px solid var(--p-color-border);
                img {
                    max-width: 100%;
                    height: auto;
                }
            }
            .review-detail-swiper--btn {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                background-color: transparent;
                border: none;
                width: 22px;
                height: 44px;
                border-radius: 10.8px;
                overflow: hidden;
                opacity: 0;
                &.left--btn {
                    left: 16px;
                }
                &.right--btn {
                    right: 16px;
                }
                .review-detail-swiper--btn__bg {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: #ffffff;
                    opacity: 0.5;
                    z-index: -1;
                }
                &:hover {
                    cursor: pointer;
                    .review-detail-swiper--btn__bg {
                        opacity: 0.7;
                    }
                }
            }

            .review-detail--action {
                position: absolute;
                bottom: 16px;
                width: 100%;
                transition: all linear 0.1s;
                opacity: 0;
                transform: translateY(5px);
                .Polaris-InlineStack {
                    width: 100%;
                    .Polaris-Button {
                        &:first-child {
                            .Polaris-Button__Icon {
                                .Polaris-Icon {
                                    display: flex;
                                    align-items: center;
                                }
                            }
                        }
                    }
                }
            }
            &:hover {
                .review-detail--action {
                    opacity: 1;
                    transform: translateY(0);
                }
                .review-detail-swiper--btn {
                    opacity: 1;
                }
            }
        }
        .review-detail-media-list--container {
            .review-detail-media-list--item {
                width: 64px;
                height: 64px;
                overflow: hidden;
                border-radius: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid var(--p-color-border);
                position: relative;
                cursor: pointer;
                img {
                    object-fit: cover;
                }
                &.select,
                &:hover {
                    border-color: transparent;
                    &::before {
                        content: "";
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        border-radius: 8px;
                        overflow: hidden;
                        border: 2px solid #005bd3;
                        z-index: 1;
                    }
                }
            }
        }
        @media (max-width: 768px) {
            > #all-media-page__review-detail--wrap.Polaris-Box {
                --pc-box-shadow: unset !important;
                --pc-box-border-radius: 0 !important;
                --pc-box-border-width: 0 !important;
                --pc-box-padding-block-start-xs: var(--p-space-500) !important;
            }
        }
        .limit-review--overlay {
            position: relative;
            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 999;
                background-color: rgba($color: #FFFFFF, $alpha: 0.4);
            }
            .review-detail--action {
                opacity: 1;
                transform: translateY(0);
            }
            .review-detail-swiper--btn {
                opacity: 1;
            }
            .review-detail-media-list--item {
                &.select {
                    &::before {
                        border-color: #000;
                    }
                }
            }
        }
    }

    &__customer-info-card--container {
        @media (max-width: 768px) {
            > #all-media-page__customer-info-card--wrap.Polaris-Box {
                --pc-box-shadow: unset !important;
                --pc-box-border-radius: 0 !important;
                --pc-box-border-width: 0 !important;
                --pc-box-padding-block-start-xs: 0 !important;
            }
        }
        .all-media-page__customer-info--avt {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #002d2d;
            background-color: #91e0d6;
            text-transform: uppercase;
            @media (max-width: 490px) {
                display: none;
            }
        }
    }

    &__primary-section {
        height: calc(98vh - 76px);
        max-height: 758px;
        overflow: hidden;
        #all-media-page__primary--wrap {
            height: 100%;
        }
        .all-media-list--container {
            padding: 16px 20px;
            height: calc(100% - 130px);
            overflow-y: scroll;
            scrollbar-width: none;
            .all-media-list--wrap {
                display: grid;
                gap: 8px;
                grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
            }
        }
        .all-media_media--item {
            position: relative;
            width: 100%;
            aspect-ratio: 1/1;
            border-radius: 8px;
            overflow: hidden;
            border: 1px solid var(--p-color-border);
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            .all-media_media-item--overlay {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                opacity: 0;
                transition: all linear 0.2s;
                pointer-events: none;
                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    opacity: 0.5;
                    background-color: #000;
                    z-index: -1;
                }
                .all-media_media-item--action {
                    position: absolute;
                    padding: 12px 12px 0;
                    left: 0;
                    right: 0;
                    opacity: 0;
                    pointer-events: none;
                    transform: translateY(-12px);
                    transition: all linear 0.2s;
                    z-index: 10;
                    .all-media_media-item--check-box {
                        padding: 4px;
                        border-radius: 4px;
                        overflow: hidden;
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 24px;
                        height: 24px;
                        .Polaris-Box {
                            width: 16px;
                            height: 16px;
                            overflow: hidden;
                            display: flex;
                            span.Polaris-Choice__Control {
                                width: 16px;
                                height: 16px;
                            }
                            span.Polaris-Checkbox {
                                margin: 0;
                                width: 16px;
                                height: 16px;
                            }
                        }
                        &::before {
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 0;
                            bottom: 0;
                            right: 0;
                            background-color: #fff;
                            opacity: 0.4;
                        }
                    }
                }
                .all-media_media-item-zoom--overlay {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: center;
                    align-items: center;
                    color: #ffffff;
                    gap: 2px;
                    opacity: 0;
                    pointer-events: none;
                    transition: all linear 0.2s;
                    z-index: 5;
                }
            }
            img {
                object-fit: contain;
                max-width: 100%;
            }

            &.review-selected {
                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    background-color: transparent;
                    z-index: 4;
                    border: 4px solid #005bd3;
                    pointer-events: none;
                    border-radius: 8px;
                }
            }

            &:hover {
                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    background-color: transparent;
                    z-index: 4;
                    border: 4px solid #005bd3;
                    pointer-events: none;
                    border-radius: 8px;
                }
                .all-media_media-item--action,
                .all-media_media-item-zoom--overlay,
                .all-media_media-item--overlay {
                    opacity: 1;
                    transform: translateY(0);
                    pointer-events: all;
                }
            }

            &.selected-media--item {
                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    background-color: transparent;
                    z-index: 4;
                    border: 4px solid #005bd3;
                    pointer-events: none;
                    border-radius: 8px;
                }
                .all-media_media-item--action,
                .all-media_media-item--overlay {
                    opacity: 1;
                    transform: translateY(0);
                    pointer-events: all;
                }
            }

            .all-media_media-item--index {
                position: absolute;
                bottom: 12px;
                right: 12px;
                padding: 2px 4px;
                background-color: #ffffff;
                border-radius: 4px;
            }
        }
    }
}
