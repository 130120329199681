.app-cs-menu{
  position: fixed;
  z-index: 9999;
  bottom: 0;
  left: 0;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  transition: all 0.3s ease;
  transform: translate(-100%, 100%);
  width: 200px;
  border: 1px solid #ccc;
  &.is-active{
    transform: none;
  }
  &-toggle{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    cursor: pointer;
    position: absolute;
    left: 100%;
    bottom: 0;
    background: #ffffff;
    border: 1px solid #ccc;
    z-index: 1;
  }
  &-wrap{
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    transition: all 0.3s ease;
    box-shadow: rgba(26, 26, 26, 0.2) 0px 4px 6px -2px;
    border: 1px solid #ccc;
    background: #ffffff;
    a{
      padding: 10px 15px;
      text-align: none;
      color: #333;
      display: flex;
      align-items: center;
      text-decoration: none;
      border-bottom: 1px solid #ccc;
    }
  }
}

.app-cs-fullscreen{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  background: #ffffff;
  &-header{
    height: 57px;
    border-bottom: 1px solid #cccccc;
  }
  &-body{
    height: calc(100% - 57px);
    overflow: hidden auto;
  }
}