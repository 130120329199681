main {
    &.Polaris-Frame__Main#AppFrameMain {
        max-width: unset;
    }
}
.onboarding-container {
    height: 100%;
    .onboarding-content {
        box-sizing: border-box;
        padding: 40px 15px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 980px;
        margin: auto;
        height: fit-content;
        max-height: calc(100% - 70px);
        overflow: auto;
        scrollbar-width: none;
        .onboarding-header {
            padding-bottom: 8px;
            .onboarding-process {
                &--container {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    align-items: center;
                    gap: 16px;
                    justify-content: center;
                    span {
                        display: inline-block;
                        width: 8px;
                        text-align: right;
                    }
                }
                &--bar {
                    display: block;
                    width: 120px;
                    height: 8px;
                    border-radius: 12px;
                    background-color: #e3e3e3;
                    overflow: hidden;
                    &__fill {
                        background-color: #303030;
                        height: 100%;
                        transition: width ease-out 0.3s;
                    }
                }
            }
        }

        .onboarding-middle {
            width: 100%;
            .title {
                text-align: center;
                margin-bottom: 10px;
                color: #303030;
                font-size: 24px;
                font-weight: 700;
                line-height: 32px;
                letter-spacing: -0.2px;
                white-space: pre-wrap;
                @media (max-width: 768px) {
                    font-size: 22px;
                }
            }
            .subtitle {
                color: #6d7175;
                margin: auto;
                text-align: center;
                font-weight: 450;
                font-size: 13px;
                line-height: 20px;
                max-width: 575px;
                white-space: pre-wrap;
            }

            .add-widget--helptext {
                text-align: center;
                text-decoration: underline;
                cursor: pointer;
                color: #005bd3;
                size: 13px;
                font-weight: 450;
                &:hover {
                    color: #0069f1;
                }
            }

            .onboarding-step--1 {
                margin: auto;
                .welcome {
                    max-width: 620px;
                }
                .demo-image {
                    width: 100%;
                    height: 100%;
                    max-width: 652px;
                    max-height: 404px;
                    background-color: #e3f9fa;
                    border-radius: 10px;
                    overflow: hidden;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    object-fit: cover;
                }
                .select-language {
                    display: block;
                    position: unset;
                    .list-language--wrap {
                        display: flex;
                        position: unset;
                        width: 320px;
                    }
                }
            }

            .onboarding-step--4 {
                max-width: 652px;
                margin: auto;

                .demo-image {
                    width: 100%;
                    height: 100%;
                    background-color: #e3f9fa;
                    border-radius: 10px;
                    overflow: hidden;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    object-fit: cover;
                }
            }

            .onboarding-step {
                &__main-section {
                    width: 63.16%;
                    max-width: 600px;
                    height: 390px;
                    overflow-y: hidden;
                    >.Polaris-Box{
                        height: 100%;
                    }
                    @media (max-width: 768px) {
                        width: 100%;
                        max-width: unset;
                    }

                    .email-schedule-setting--list {
                        width: 100%;
                        padding-right: 4px;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 12px;
                        flex: 1;
                        max-height: 270px;
                        overflow-y: auto;
                        scrollbar-width: none;
                        overflow-x: visible;
                    }
                    .email-schedule-setting--item {
                        width: 100%;
                        .Polaris-InlineGrid {
                            width: 100%;
                        }
                    }
                }
                &__sub-section {
                    width: 34.74%;
                    max-width: 330px;
                    min-height: 390px;
                    border-radius: 12px;
                    border: 1px solid var(--p-color-border);
                    overflow: hidden;
                    padding: 20px;
                    background-color: var(--p-color-bg-fill);
                    > div {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 350px;
                        img {
                            width: 100%;
                            max-width: 226px;
                            height: auto;
                            object-fit: contain;
                        }
                    }
                    &.full-width--img {
                        padding: 0;
                        > div {
                            height: 390px;
                            img {
                                max-width: 100%;
                                height: 100%;
                                width: auto;
                                object-fit: fill;
                            }
                        }
                    }
                    @media (max-width: 768px) {
                        display: none;
                        max-width: unset;
                    }
                }
            }

            .onboarding-embed-widget {
                &__main-section {
                    width: 68.35%;
                    max-width: 622px;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    button.Polaris-Button--toneSuccess {
                        background-color: #cdfee1 !important;
                        color: #0c5132 !important;
                    }
                    .Polaris-InlineStack {
                        p {
                            font-size: 13px;
                            font-weight: 400;
                            line-height: 20px;
                            color: #303030;
                            span {
                                font-weight: 700;
                            }
                        }
                    }
                    .guide-list-no--style {
                        padding-left: 24px;
                    }
                    .embed-widget-step--number {
                        width: 20px;
                        height: 20px;
                        border-radius: 50%;
                        background-color: #e3e3e3;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    @media (max-width: 768px) {
                        width: 100%;
                        max-width: unset;
                    }
                }
                &__sub-section {
                    width: 29.67%;
                    max-width: 270px;
                    border-radius: 12px;
                    overflow: hidden;
                    @media (max-width: 768px) {
                        display: none;
                        max-width: unset;
                    }
                }
            }
        }
    }

    .onboarding-footer {
        box-sizing: border-box;
        padding: 20px 15px 10px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        max-width: 980px;
        margin: auto;
        .Polaris-Button--disabled {
            background: var(--pc-button-bg_disabled) !important;
            color: var(--pc-button-color_disabled) !important;
            box-shadow: none !important;
            -webkit-user-select: none !important;
            user-select: none !important;
            pointer-events: none !important;
        }
    }
}

.onboarding-pricing--page {
    height: 100%;
    #onboarding-pricing-free--btn {
        text-decoration: underline;
    }
    .pricing-benefit--item {
        font-size: 13px;
        line-height: 20px;
        span {
            font-weight: 700;
        }
    }
    .pricing-table {
        border-radius: 12px;
        border: 1px solid var(--p-color-border);
        background-color: var(--p-color-bg-fill);
        height: 256px;
        overflow: hidden;
        transition: height linear 0.5s;
        position: relative;
        .pricing-detail--item {
            border-bottom: 1px solid var(--p-color-border);
            &:last-child {
                border: none;
            }
        }
        .view-more--detail {
            position: absolute;
            bottom: 0;
            z-index: 1;
            width: 100%;
            padding: 12px;
            background-color: var(--p-color-bg-fill);
            border-top: 1px solid var(--p-color-border);
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.onboarding-modal-compare--plans {
    .compare-plan--quota {
        p {
            font-size: 13px;
            line-height: 20px;
            font-weight: 400;
            span {
                font-weight: 700;
            }
        }
    }
    .compare-card--wrap{
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 12px;
    }
    .pricing-benefit--item {
        font-size: 13px;
        line-height: 20px;
        span {
            font-weight: 700;
        }
    }
}

.toggle-button {
    display: block;
    border: none;
    width: 32px;
    height: 20px;
    background-color: #8a8a8a;
    border-radius: 6px;
    padding: 4px;
    cursor: pointer;
    .round {
        width: 12px;
        height: 12px;
        border-radius: 4px;
        background-color: #ffffff;
    }
    &.toggle-active {
        background-color: #1a1a1a;
        .round {
            float: right;
        }
    }
    &.toggle-button-disabled {
        cursor: default;
        position: relative;
        &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: #bbbbbb;
            opacity: 0.5;
            border-radius: 6px;
        }
    }
}
.modal-video {
    width: 100%;
    height: 100%;
    img {
        width: 100%;
        height: 100%;
    }
}

.import-resource--item {
    width: fit-content;
    min-width: 116px;
    aspect-ratio: 1/1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    background-color: var(--p-color-bg);
    gap: 8px;
    padding: 8px;
    cursor: pointer;
    img {
        width: 40px;
        aspect-ratio: 1/1;
    }
    p {
        max-width: 100px;
        text-align: center;
    }
}

.add-block-script--guide {
    padding-bottom: 20px;
    .left {
        width: 90%;
        max-width: 388px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @media (max-width: 900px) {
            width: 90%;
        }
        .guide {
            p {
                font-size: 13px;
                font-weight: 450;
                line-height: 20px;
            }
            &-step {
                p {
                    margin-bottom: 6px;
                    padding-left: 28px;
                    position: relative;
                    &:last-child {
                        margin: 0;
                    }
                    span {
                        font-weight: 700;
                    }
                    a {
                        font-weight: 700;
                    }
                    &::before {
                        content: "";
                        display: block;
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10' fill='none'%3E<path opacity='0.6' d='M10 5C10 7.76142 7.76142 10 5 10C2.23858 10 0 7.76142 0 5C0 2.23858 2.23858 0 5 0C7.76142 0 10 2.23858 10 5Z' fill='%23949494'/%3E%3C/svg%3E");
                        width: 10px;
                        height: 10px;
                        position: absolute;
                        left: 0;
                        top: 10px;
                        transform: translateY(-50%);
                    }
                    &:last-child {
                        &::before {
                            background: unset;
                        }
                        button {
                            &.Polaris-Button--toneSuccess {
                                background-color: #cdfee1 !important;
                                color: #0c5132 !important;
                                span {
                                    font-weight: 550;
                                }
                            }
                        }
                    }
                }
                button {
                    &.Polaris-Button--toneSuccess {
                        background-color: #cdfee1 !important;
                        color: #0c5132 !important;
                        span {
                            font-weight: 550;
                        }
                    }
                }
            }
            .guide-list-no--style {
                padding-left: 28px;
                margin-bottom: 6px;
            }
        }
    }
    .right {
        border: 1px solid #e9e9e9;
        border-radius: 12px;
        position: relative;
        overflow: hidden;
        max-height: 290px;
        aspect-ratio: var(--aspectRatio);
        @media (max-width: 900px) {
            width: 90%;
            max-height: unset;
        }
        img {
            width: 100%;
            height: 100%;
        }
    }
}
