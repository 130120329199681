.setting-header--sticky {
    position: sticky;
    top: 0;
    background-color: #f1f1f1;
    z-index: 99;
}

.loading-box {
    width: 100%;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

#editBox {
    .Polaris-Labelled--hidden {
        display: none;
    }
}

.option-title {
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
    color: #202223;
}
.general-setting {
    .general-setting-item {
        .item--title {
            font-size: 16px;
            line-height: 100%;
            font-weight: 600;
        }
        .Polaris-Box {
            .Polaris-Connected {
                .Polaris-TextField__Backdrop {
                    border-radius: 4px !important;
                }
            }
        }
        #editBox {
            .Polaris-BlockStack {
                height: 100%;
            }
        }
    }
}

.review-setting {
    .protect-review--section {
        .language-tab--item {
            padding: 0 6px 0 12px;
            gap: 2px;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            text-transform: capitalize;
            border-radius: 8px;
            font-size: 12px;
            font-weight: 550;
            line-height: 133.4%;
            height: 28px;
            &:first-child {
                padding-right: 12px;
            }
            &.tab--active {
                background-color: #ebebeb;
            }
            &:not(.tab--disable) {
                cursor: pointer;
                &:hover {
                    background-color: #ebebeb;
                }
                & > span {
                    cursor: pointer;
                    border-radius: 4px;
                    &:hover {
                        background-color: #d4d4d4;
                    }
                }
            }

            &.tab--disable {
                cursor: default;
                color: var(--p-color-text-secondary);
            }
        }

        #add-new-lang--btn {
            cursor: pointer;
            width: 32px;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            &:hover {
                background-color: #f2f2f2;
            }
            &.add-new-lang--btn__disable {
                &:hover {
                    cursor: default;
                    background-color: transparent;
                }
            }
        }
    }
    .review-setting--card {
        .Polaris-ShadowBevel {
            z-index: unset;
        }
    }
    .review-translate--setting {
        @media (max-width: 400px) {
            .review-translate--example {
                display: none;
            }
        }
    }
}

.setting {
    .setting-card--disable {
        .Polaris-Select--disabled .Polaris-Select__Backdrop {
            background-color: var(--p-color-bg-surface);
        }
        .Polaris-TextField--disabled > .Polaris-TextField__Backdrop {
            background-color: var(--p-color-input-bg-surface);
            border: var(--p-border-width-0165) solid
                var(--p-color-border-disabled);
        }
        img {
            filter: grayscale(1);
        }
    }
}

.review-setting--warning {
    .Polaris-HorizontalStack {
        Span.Polaris-Icon {
            margin: 0;
        }
    }
}

.review-star-checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    input {
        margin: 0;
        margin-right: 8px;
        width: 18px;
        height: 18px;
        border: 2px solid #8c9196;
        border-radius: 4px;
        cursor: pointer;
        &:hover {
            outline: 2px solid #458fff;
        }
    }
    label {
        line-height: 100%;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        cursor: pointer;
    }
}

.filter-review {
    .item--title,
    .generate-star-title {
        font-size: 16px;
        line-height: 100%;
        font-weight: 600;
    }

    .Polaris-Box {
        .Polaris-Connected {
            .Polaris-TextField__Backdrop {
                border-radius: 4px !important;
            }
        }
    }

    .quantity_generate {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        gap: 10px;
        .auto_generate_quantity {
            height: fit-content;
            min-height: 50px;
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            justify-content: flex-end;
            gap: 5px;
            @media (max-width: 600px) {
                min-height: unset;
            }
        }
    }

    .generate-title-heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.generate-star-box {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: max-height 0.5s ease, opacity 0.5s ease;
}

.generate-star-box.expanded {
    max-height: 500px;
    opacity: 1;
}

.pill-button {
    position: relative;
    display: inline-block;
    width: 37px;
    height: 20px;
    border-radius: 50px;
    background-color: #dddddd;
    overflow: hidden;
    cursor: pointer;
    border: none;
    padding: 0;
}

.circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-110%, -50%);
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #ffffff;
    transition: transform 0.3s;
}

.pill-button.active .circle {
    transform: translate(0%, -50%);
}

.pill-button.active {
    background-color: #007f60;
}

.import-review-checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    input {
        margin: 0;
        margin-right: 8px;
        width: 17px;
        height: 17px;
        border: 2px solid #8c9196;
        border-radius: 4px;
        cursor: pointer;
        &:hover {
            outline: 2px solid #458fff;
        }
    }
    label {
        line-height: 100%;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        cursor: pointer;
    }
}

.custom-input {
    box-sizing: border-box;
    font-size: var(--p-font-size-400);
    font-weight: var(--p-font-weight-regular);
    line-height: var(--p-font-line-height-600);
    letter-spacing: initial;
    position: relative;
    z-index: var(--pc-text-field-contents);
    width: 100%;
    min-width: 0;
    min-height: var(--pg-control-height);
    padding: var(--p-space-150) var(--p-space-150) var(--p-space-150)
        var(--p-space-300);
    font-family: var(--p-font-family-sans);
    caret-color: var(--p-color-text);
    color: var(--p-color-text);
    align-items: center;
    border-radius: var(--p-border-radius-200);
    border: var(--p-border-width-0165) solid var(--p-color-input-border);
    @media (min-width: 48rem) {
        font-size: var(--p-font-size-325);
        line-height: var(--p-font-line-height-500);
    }
    &:focus,
    &:hover {
        border-color: var(--p-color-input-border-active);
        border-width: var(--p-border-width-025);
        outline: var(--p-border-width-050) solid var(--p-color-border-focus);
        outline-offset: var(--p-space-025);
    }
    &.disabled,
    &.quantity-disabled {
        opacity: 1;
        background: none;
        color: var(--p-color-text-disabled);
        -webkit-text-fill-color: var(--p-color-text-disabled);
        border-color: transparent;
        background-color: var(--p-color-bg-surface-disabled);
        outline: none;
        cursor: auto !important;
    }
    span {
        margin: 0;
    }
    &.error {
        background-color: var(--p-color-bg-surface-critical);
        border-color: var(--p-color-border-critical-secondary);
    }
}

// global display
.global-setting--icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    svg {
        width: 32px;
        height: 32px;
        path {
            fill: #ffb400;
        }
    }
    &.gray-scale {
        filter: grayscale(1);
    }
}

.email-unsubscribe--content {
    .unsubscribe-email--table {
        .Polaris-IndexTable__TableRow {
            .unsubscribe-email-del--btn {
                opacity: 0;
            }
            &.Polaris-IndexTable__TableRow--hovered {
                .unsubscribe-email-del--btn {
                    opacity: 1;
                }
            }
        }
        .email-unsubscribe-custom--filter {
            transition: height linear 0.2s;
            height: 44px;
            &.active {
                height: 87px;
                .Polaris-TextField__Input {
                    min-height: 28px;
                }
            }
            button.Polaris-Button#email-unsubscribe-date-filter--activator {
                box-shadow: none;
                border: 1px dashed var(--p-color-border);
                padding: 1px 8px 1px 12px;
                box-sizing: border-box;
                height: 26px;
                min-height: unset;
            }
            .email-unsubscribe-custom--close {
                width: 100%;
                display: none;
                &.show {
                    display: block;
                }
            }
            .email-unsubscribe-custom--open {
                padding: 8px;
                width: 100%;
                display: none;
                &.show {
                    display: block;
                }
            }
        }
        &.disable {
            .Polaris-IndexTable-ScrollContainer {
                position: relative;
                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    background-color: rgba(229, 229, 229, 0.5);
                    z-index: 99;
                }
            }
        }
    }
}
