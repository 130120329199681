.qr-code-management--container {
    .qr-preview--container {
        .qr-code--wrap {
            .qr-code--title {
                display: block;
                width: 100%;
                height: 100%;
                text-align: center;
            }
            .qr-code--field {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                canvas,
                svg {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
    canvas,
    svg {
        width: 100%;
        height: 100%;
    }
}
.qr-code-customize--container {
    .qr-code-customize-setting--container {
        padding-right: 16px;
        &:scrollbar-width {
            width: 6px;
        }
        &::-webkit-scrollbar {
            width: 6px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #b5b5b5;
            border-radius: 10px;
        }
        &::-webkit-scrollbar-track {
            background-color: #e3e3e3;
            border-radius: 10px;
        }
        .qr-code-setting__logo--item {
            width: 72px;
            height: 72px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #f3f3f3;
            border-radius: 4px;
            position: relative;
            cursor: pointer;
            img {
                width: 40px;
                height: 40px;
            }
            .qr-code__logo--item-close-btn{
                position: absolute;
                top: -5px;
                right: -5px;
                background-color: #FFFFFF;
                border-radius: 12px;
                display: flex;
                justify-content: center;
                align-items: center;
                box-shadow: 0 1px 2px 2px rgb(235, 235, 235);
            }
            .Polaris-DropZone {
                background-color: transparent;
                border: none;
                &::after {
                    border: none !important;
                }
            }
        }
    }
    #qr-code-demo--field {
        display: flex;
        svg,
        canvas {
            width: 100%;
            height: 100%;
        }
    }
}
