@mixin col() {
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin flex($width) {
  flex: 0 0 $width;
  max-width: $width;
}
.flex {
  display: flex;

  &-auto {
    flex: 1 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &-col {
    &-24 {
      @include flex(24px);
    }
  }
}
