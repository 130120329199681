.pricing-page {
    .pricing-plan--container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;
        .pricing-foreword {
            max-width: 100% !important;
            p {
                text-align: center;
            }
        }
        .pricing-card {
            border: none;
            padding: 16px;
            border-radius: 8px;
            &::before {
                border: none;
                box-shadow: none;
            }
            &.best-choice {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
        }
    }
    .pricing-table {
        border-radius: 8px;
        border: 1px solid #e5e7e5;
        &>div{
            border-bottom: 1px solid #e5e7e5;
            &:last-child {
                border-bottom: none;
            }
        }
    }
    @media screen and (min-width: 971px) {
        .pricing-desktop--hidden {
            display: none;
        }
    }
    @media screen and (max-width: 704px) {
        .pricing-plan--container {
            & > div > .Polaris-InlineStack {
                flex-direction: column;
            }
            .pricing-card {
                &--container {
                    width: 100% !important;

                    .pricing-card {
                        min-width: 180px !important;
                        width: 100% !important;
                    }
                }
            }
        }
    }
}

.pricing-offer {
    @media (max-width: 768px) {
        .Polaris-CalloutCard {
            flex-direction: column;
            gap: 8px;
            .Polaris-CalloutCard__Content {
                text-align: center;
                width: 100%;
                p {
                    font-size: 12px !important;
                }
                .Polaris-InlineStack {
                    gap: 8px;
                    justify-content: center;
                }
            }
        }
    }
}
