.before-email--new {
    .voucher-icon--item {
        cursor: pointer;
        &.active {
            border: 1px solid #303030;
        }
    }
    .message-edit--field {
        border-radius: 4px;
        border: 1px solid #cbced1;
        .message-edit--field__toolbar {
            padding: 15px;
            border-bottom: 1px solid #cbced1;
            background-color: #f6f6f7;
            border-radius: 4px 4px 0 0;
            ul {
                li {
                    background-color: #ffffff;
                    border: 1px solid #cbced1;
                    &.active {
                        background-color: rgba(174, 180, 185, 0.1882352941);
                    }
                    &:first-child {
                        border-top-left-radius: 4px;
                        border-bottom-left-radius: 4px;
                        border-right: 0;
                    }
                    &:last-child {
                        border-top-right-radius: 4px;
                        border-bottom-right-radius: 4px;
                        border-left: 0;
                    }
                }
            }
        }

        .message-edit--field__editor {
            .Polaris-TextField__Backdrop {
                border: none;
                outline: none;
            }
        }
    }
    .before-email-color--setting {
        max-width: 50%;
        h5 {
            font-weight: 500;
            margin-bottom: 4px;
        }
    }
}

#voucher-demo--container {
    .voucher-demo--voucher {
        margin-top: 20px;
        padding: 16px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        background-color: var(--demo-message_background_color);
        svg {
            path {
                fill: var(--demo-icon_and_highlight_color);
            }
        }
        .voucher-demo--icon {
            margin: auto;
        }

        .voucher-demo--button {
            gap: 10px;
            border-radius: 8px;
            padding: 12px;
            background-color: var(--demo-button_background_color);
            display: flex;
            align-items: center;
            font-size: 16px;
            font-weight: 700;
            line-height: 19.36px;
            text-align: left;
            color: var(--demo-button_title_color);
            width: fit-content;
            margin: auto;
            &:empty{
                display: none;
            }
        }
    }
}
