.Polaris-PositionedOverlay{
  .Polaris-Popover{
    .Polaris-Popover__Content{
      .Polaris-Popover__Pane{
        &.Polaris-Scrollable.Polaris-Scrollable--hasBottomShadow::after{
          box-shadow: none;
        }
      }
    }
  }
}
