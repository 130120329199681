.page-login {
  position: relative;
}
.gradient-background {
  background-color: #aeaeae;
  position: fixed;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.page {
  &-main {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    z-index: 0;
    background-color: white;

  }
  &-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1;
    max-width: 34rem;
    width: 95%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background: white;
    border-radius: 8px;
    margin-top: 2vw;
    margin-bottom: 2vw;
    box-shadow: 6px 12px 60px rgba(0,0,0,0.2);
    padding-bottom: 50px;
    .ui-subheading {
      margin-bottom: 30px;
    }
  }
}

.login-card {
  padding: 2.5rem 2.5rem 0;
  &__header {
    margin-bottom: 2.5rem;
  }
  &__logo {
    width: 7.3125rem;
  }
}