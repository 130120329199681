.Polaris-IndexTable__TableRow {
  margin-top: 25px;
}
.Polaris-IndexTable__TableRow td:first-child {
  padding-top: 7px;
}
.Polaris-IndexTable {
  padding: 0;
}

.Polaris-IndexTable__TableRow td {
  vertical-align: middle;
}

.table__ {
  margin-bottom: 15px;
}

.display--customerIn4 {
  .display--customerIn4__email {
    display: flex;
    .Polaris-Icon {
      margin-left: 4px;
    }
  }
}

.edit--email {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .input--email {
    font-size: 12px;
    padding: 2px 5px;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    .Polaris-Button {
      margin-left: 25px;
    }
  }
}

.Polaris-IndexTable__BulkActionsWrapper {
  .Polaris-InlineStack {
    display: flex;
    align-items: center;
    justify-content: space-between;
    right: 0;
  }
}

@media screen and (max-width: 768px) {
  .Polaris-IndexTable__SelectAllActionsWrapper {
    position: relative;
    z-index: 0;
  }

  .Polaris-IndexTable__SelectAllActionsWrapper .Polaris-Button {
    margin-right: 0;
  }

  .Polaris-Button {
    font-size: 11px;
  }
  .Polaris-IndexTable__BulkActionsWrapper {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: -35px;
  }
}
