.email-header {
    --pc-box-padding-block-end-xs: var(--p-space-300);
    --pc-box-padding-block-end-md: var(--p-space-300);
    --pc-box-padding-block-start-xs: var(--p-space-300);
    --pc-box-padding-block-start-md: var(--p-space-300);
    --pc-box-padding-inline-start-xs: var(--p-space-800);
    --pc-box-padding-inline-start-sm: var(--p-space-800);
    --pc-box-padding-inline-end-xs: var(--p-space-1000);
    --pc-box-padding-inline-end-sm: var(--p-space-1000);
    border-bottom: 1px solid #c9cccf;
    position: relative;
}

.email-setting--header {
    .Polaris-Icon {
        margin: 0;
    }
}

.btn-plain {
    color: var(--p-color-text-emphasis);
    text-decoration: none;
    margin: calc(var(--pc-button-vertical-padding) * -1)
        calc(var(--p-space-200) * -1);
    padding-left: var(--p-space-200);
    padding-right: var(--p-space-200);
    background: transparent;
    border: 0;
    box-shadow: none;
}
.step-lists {
    position: relative;
    width: 100%;
    max-width: 760px;
    height: 30px;
    margin: auto;
    margin-top: 13px;
    text-align: center;

    &:before {
        content: "";
        width: 100%;
        height: 1px;
        background-color: #e1e3e5;
        display: block;
        position: absolute;
        top: 14px;
        z-index: 1;
    }

    .step-item {
        color: #8c9196;
        width: 30px;
        height: 30px;
        line-height: 24px;
        border: 3px solid #8c9196;
        background-color: rgb(241 242 244);
        font-weight: 700;
        font-size: 16px;
        border-radius: 50%;
        display: inline-block;
        position: absolute;
        top: 0;
        z-index: 2;

        &:nth-child(1) {
            left: 0;
        }

        &:nth-child(2) {
            position: inherit;
        }

        &:nth-child(3) {
            right: 0;
        }

        &.active {
            background-color: #8c9196;
            color: #fff;
        }
    }
}

.list-campaign {
    margin-top: 15px;
    margin-bottom: 100px;
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    .campaign-item {
        background: #ffffff;
        border-radius: 5px;
        width: 350px;
        height: 100%;
        margin: auto;
        .thumb {
            width: 100%;
            height: 210px;
            border-radius: 5px 5px 0 0;
            background-color: #d9d9d9;
            box-sizing: border-box;
            img {
                width: 100%;
            }
        }

        .content {
            padding: 18px;
            width: 100%;
            box-sizing: border-box;
            height: calc(100% - 210px);
            border: 0.8px solid #e1e3e5;
            border-radius: 0px 0px 5px 5px;
            border-top: transparent;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            position: sticky;
            top: 0;
            .campain-explain {
                strong {
                    color: #21cba5;
                }
            }
        }
    }
}

#email-card {
    .Polaris-CalloutCard__Dismiss {
        .Polaris-Icon {
            height: 2rem;
            width: 2rem;
        }
    }

    .Polaris-CalloutCard__Buttons {
        display: none;
    }
}

.cust-tag {
    display: inline-block;
    border-radius: 8px;
    line-height: 133.4%;
    background: #e0f0ff;
    padding: 2px 8px;
    color: #00527c;
    font-size: 12px;
}

.cust-form-row {
    position: relative;
    .preview-tooltip {
        position: absolute;
        width: fit-content;
        top: 50%;
        transform: translateY(25%);
        border: 1px solid #e1e3e5;
        background-color: #ffffff;
        z-index: 99;
        border-radius: 5px;
        overflow: hidden;
        box-shadow: 0px 4px 6px -2px #1a1a1a33, 0px 1px 0px 0px #cccccc80 inset,
            0px -1px 0px 0px #0000002b inset, -1px 0px 0px 0px #00000021 inset,
            1px 0px 0px 0px #00000021 inset;
    }
}

.email-setting {
    &__topbar {
        &--actions {
            display: flex;
            .more-action {
                display: flex;
                margin-right: 10px;
                button,
                a {
                    margin: 0;
                    color: $text-default-color;
                    .Polaris-Button__Text {
                        font-weight: 500;
                    }
                }
            }
            .primary-action {
                button {
                    margin-left: 10px;
                }
            }
        }
    }
    &--status-text {
        margin-left: 10px;
        color: #797d82;
    }
    &--form {
        &__topbar {
            display: flex;
            position: relative;
            .tab-item {
                background: #ededed;
                border-radius: 5px 5px 0 0;
                line-height: 36px;
                padding: 0 10px 0 20px;
                display: flex;
                cursor: pointer;
                button {
                    margin-left: 10px;
                    .Polaris-Icon {
                        width: 1em;
                        height: 1em;
                    }
                }
                &.active {
                    background: #fff;
                    span {
                        font-weight: 600;
                    }
                    button {
                    }
                }
            }
            & > .Polaris-Button--plain {
                padding: 0 20px;
                color: #5c5f62;
                text-decoration: none !important;
                svg {
                    fill: #5c5f62;
                }
            }
            &--right {
                display: flex;
                align-items: center;
                button:last-child {
                    margin-left: 10px;
                }
            }
        }
        &__top-form {
            background-color: #fff;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.05),
                0 1px 2px rgba(0, 0, 0, 0.15);
            border-radius: 8px;
            padding: 10px 24px;
            .cust-form-row {
                border-bottom: 1px solid #e1e3e5;
                padding: 10px 0;
                display: flex;
                align-items: center;
                gap: 10px;
                &:last-child {
                    border-bottom: 0;
                }
                label {
                    color: #797d82;
                    width: 150px;
                    max-width: 200px;
                }
                .form-dropdown-group {
                    button {
                        color: #202223;
                        text-decoration: none;
                        svg {
                            fill: #202223 !important;
                        }
                    }
                }
                .from__right {
                    display: flex;
                    align-items: center;
                }
                .from-email {
                    display: flex;
                    margin-left: 5px;
                    margin-right: 10px;
                    color: $text-warning-color;
                    .Polaris-Icon {
                        margin-right: 5px;
                        svg {
                            fill: $text-warning-color;
                        }
                    }
                }

                .tone-voice-item {
                    width: unset;
                    padding: 6px 12px;
                    border-radius: 10px;
                    cursor: pointer;
                    p {
                        font-size: 12px;
                        font-weight: 550;
                        line-height: 16px;
                        color: #303030;
                    }
                    &.is-active {
                        background-color: #ebebeb;
                    }
                    &:hover {
                        background-color: #ebebeb;
                    }
                }
            }
        }
    }
    &--main-content {
        margin-top: 20px;
        .main-content--edit {
            background-color: #fff;
            border-radius: 0 8px 8px 0;
            width: 100%;
            .header {
                display: flex;
                align-items: center;
                height: 60px;
                border-bottom: 1px solid #e1e3e5;
                padding: 15px 20px;
                justify-content: space-between;
                h3 {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                }
                button {
                    color: #797d82;
                    svg {
                        fill: #797d82;
                    }
                }
            }
            .form-group {
                border-bottom: 1px solid #e1e3e5;
                &--radio {
                    p {
                        margin-bottom: 10px;
                    }
                }
                &:last-child {
                    border-bottom: 0;
                }
            }
            .box-editor {
                background: #ffffff;
                border: 1px solid #e3e3e3;
                border-radius: 8px;
                .toolbar {
                    border-bottom: 1px solid #e3e3e3;
                    border-radius: 4px 4px 0 0;
                    padding: 6px 12px;
                    &-grid {
                        display: flex;
                        margin-bottom: 10px;
                        & > * {
                            width: 100%;
                            &:last-child {
                                max-width: 100px;
                                margin-left: 10px;
                            }
                        }
                        &:last-child {
                            margin-bottom: 0;
                        }
                        &--title {
                            & > * {
                                width: auto;
                                max-width: 100% !important;
                            }
                        }
                    }
                }
                .rdw-editor-toolbar {
                    background: #f6f6f7;
                    border-radius: 4px 4px 0 0;
                    border: 0;
                    .rdw-dropdown-wrapper {
                        border-radius: 4px;
                        border: 1px solid #aeb4b9;
                        box-shadow: none !important;
                        height: 36px;
                    }
                }
                .rdw-dropdown-optionwrapper {
                    max-height: none;
                    overflow: auto;
                }
                .rdw-editor-main {
                    padding: 15px;
                }
            }
            .inline {
                margin-top: 15px;
            }
            &.edit-box-normal {
                .Polaris-TextField--multiline {
                    .Polaris-TextField__Backdrop,
                    .Polaris-TextField__Backdrop:after {
                        border: 0 !important;
                        box-shadow: none !important;
                    }
                }
            }
        }
        .preview-email {
            width: 100%;
            min-width: 70%;
            border-radius: 12px;
            border: 1px solid #e3e3e3;
            background-color: white;
            padding: 16px 0px 0;
            .content-preview {
                width: 90%;
                max-width: 610px;
                margin: 0 auto;
                background-color: #fff;
                position: relative;
                .content-wrap {
                    border-radius: 0px 0px 8px 8px;
                    > .header {
                        text-align: center;
                        line-height: 100px;
                        background-color: #2c7695;
                        color: #fff;
                        border: 2px solid transparent;
                    }
                }
                & > .header,
                .body-title,
                .content-body,
                .email-list-product {
                    border: 2px solid transparent !important;
                }
                .isFocus {
                    border: 2px solid #004fee !important;
                    position: relative;
                    .focus-corner {
                        position: absolute;
                        border: 1px solid #004fee;
                        width: 9px;
                        height: 9px;
                        background-color: #ffffff;
                        z-index: 1;
                        &.top-left {
                            left: -5px;
                            top: -5px;
                        }
                        &.top-right {
                            right: -5px;
                            top: -5px;
                        }
                        &.bottom-left {
                            left: -5px;
                            bottom: -5px;
                        }
                        &.bottom-right {
                            right: -5px;
                            bottom: -5px;
                        }
                    }
                }
                .edit-available:hover {
                    border: 2px solid #004fee !important;
                }

                .preview-body {
                    background-color: #fff;
                    .body-title {
                        padding: 22px 40px;
                    }
                    .content-body {
                        padding: 10px 40px;
                        p {
                            display: block;
                            line-height: 133.4%;
                            &:empty {
                                padding-top: 15px;
                            }
                        }
                        * {
                            word-wrap: break-word; /* Allow wrapping */
                            word-break: break-word; /* Break long words */
                            white-space: normal;
                        }
                    }
                    h2 {
                        margin-bottom: 15px;
                        min-height: 1px;
                    }
                    .email-list-product {
                        padding: 25px 40px;
                    }
                    .voucher-demo--container {
                        border: 2px solid transparent;
                        .voucher-demo--voucher {
                            padding: 16px;
                            display: flex;
                            flex-direction: column;
                            gap: 20px;
                            background-color: var(
                                --discount-voucher-message_background_color
                            );
                            svg {
                                path {
                                    fill: var(
                                        --discount-voucher-icon_and_highlight_color
                                    );
                                }
                            }
                            .voucher-demo--icon {
                                margin: auto;
                            }

                            .voucher-demo--button {
                                gap: 10px;
                                border-radius: 8px;
                                padding: 12px;
                                background-color: var(
                                    --discount-voucher-button_background_color
                                );
                                display: flex;
                                align-items: center;
                                font-size: 16px;
                                font-weight: 700;
                                line-height: 19.36px;
                                text-align: left;
                                color: #ffffff;
                                width: fit-content;
                                margin: auto;
                                &:empty {
                                    display: none;
                                }
                            }
                        }
                    }
                }
                .preview-footer {
                    padding: 25px;
                    text-align: center;
                    border-width: 2px;
                    border-style: solid;
                    border-color: var(--p-color-border) transparent transparent;
                    display: flex;
                    flex-direction: column;
                    gap: 14px;
                    ul {
                        margin: 0;
                        padding: 0;
                        list-style: none;
                        height: 32px;
                        li {
                            display: inline-block;
                            padding: 0 5px;
                            div{
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                width: 32px;
                                height: 32px;
                                border-radius:  50%;
                                background-color: rgba(241, 241, 241, 1);
                                img{
                                    width: initial;
                                    height: auto;
                                }
                            }
                        }
                    }
                    .footer-info {
                        color: #939393;
                        a {
                            color: #939393;
                        }
                    }
                    .mobile-app-link{
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        justify-content: center;
                        gap: 16px;
                        img{
                            width: 100%;
                            max-width: 160px;
                            height: auto;

                        }
                    }
                    .unsubscribe-btn{
                        color: #939393;
                        text-decoration: underline;
                    }
                }
                .email-list-product {
                    margin: 0;
                    padding: 0;
                    list-style: none;
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    .email-product--item {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        .mobile-view {
                            flex-direction: column;
                        }
                        &__wrap {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: nowrap;
                            gap: 10px;
                            align-items: center;

                            .thumb {
                                width: 76px;
                                height: 76px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                border-radius: 8px;
                                border: 1px solid #e3e3e3;
                            }
                            .email-product__right {
                                h3 {
                                    margin-bottom: 5px;
                                    min-height: 25px;
                                    .product-name {
                                        font-size: 16px;
                                        font-weight: 500;
                                        line-height: 20px;
                                    }
                                }
                                .rate {
                                    label {
                                        display: block;
                                    }
                                }
                                .star-rates {
                                    display: flex;
                                    flex-direction: row;
                                    flex-wrap: nowrap;
                                    gap: 3.6px;
                                }
                            }
                        }
                        .call-to-action--btn {
                            font-size: 12px;
                            font-weight: 450;
                            line-height: 16px;
                            border: 1px solid #e3e3e3;
                            border-radius: 4px;
                            background-color: #ffffff;
                            padding: 10px 12px;
                            line-height: 100%;
                            min-width: 80px;
                            text-align: center;
                            white-space: normal;
                            word-wrap: break-word;
                        }
                    }
                }
                .preview--review-box {
                    margin: 0 40px 50px;
                    .review-item {
                        padding: 16px;
                        border: 1px solid;
                        border-color: #cccccc transparent;
                        display: grid;
                        gap: 8px;

                        &__header {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: nowrap;
                            align-items: center;
                            .star-rates {
                                display: flex;
                                flex-direction: row;
                                gap: 2.6px;
                                .Polaris-Icon--toneBase {
                                    svg {
                                        fill: #ffb400;
                                    }
                                }
                            }
                            .review-item_time {
                                p {
                                    margin: 0;
                                    line-height: 100%;
                                    font-size: 12px;
                                    color: #97a2b3;
                                    font-weight: 400;
                                    margin-left: 5px;
                                }
                            }
                        }
                        &__body {
                            display: grid;
                            gap: 8px;
                            .review-item_title {
                                font-size: 12px;
                                font-weight: 700;
                                color: #000000;
                                opacity: 0.87;
                                line-height: 20px;
                                margin: 0;
                            }
                            .review-item_content {
                                margin: 0;
                                font-size: 12px;
                                font-weight: 400;
                                line-height: 20px;
                                letter-spacing: 0.14px;
                            }
                            .review-item_media {
                                display: flex;
                                flex-direction: row;
                                flex-wrap: nowrap;
                                gap: 8px;
                                .thumb {
                                    width: 60px;
                                    height: 60px;
                                    border: 1px solid #e3e3e3;
                                    border-radius: 8px;
                                    overflow: hidden;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    &:last-child {
                                        margin: 0;
                                    }
                                }
                            }
                        }
                    }
                    .review-action {
                        margin-top: 10px;
                        text-align: center;
                        button {
                            font-size: 18px;
                            padding: 15px 30px;
                            border-radius: 100px;
                            background: #2c7695;
                            color: #fff;
                            box-shadow: none;
                            border: 0;
                        }
                    }
                }
            }
            &.mb {
                .content-preview {
                    width: 390px;
                }
                .preview--review-box .review-item {
                    &__right {
                        padding: 5px 10px;
                    }
                    .bottom {
                        display: block;
                    }
                }
            }
        }
    }
}

.place-holder-upload {
    display: flex;
    width: 100%;
    aspect-ratio: 4/1;
    border: 1px dotted #aeb4b9;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
}

.box-current-img {
    border-radius: 12px;
    border: 1px solid #e3e3e3;
    padding: 16px;
    width: 100%;
    height: 180px;
    overflow: hidden;
    .img-wrap {
        width: 100%;
        border-radius: 8px;
        overflow: hidden;
        width: 100%;
        aspect-ratio: 3.2/1;
        max-height: 115px;
        img {
            width: 100%;
            height: auto;
            object-fit: contain;
        }
    }
    .Polaris-ButtonGroup {
        justify-content: center;
    }
    .hidden {
        display: none;
    }
}

.language-popover-body {
    width: 250px;
    align-items: stretch;
    border-top: 1px solid #dfe3e8;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    position: relative;
    height: 100%;
    max-height: 400px;
    overflow: hidden;
    

    &.all-languages {
        width: 300px;
        padding: 20px 15px;
        height: auto;

        .Polaris-Tag {
            margin-right: 3px;
            margin-bottom: 3px;
        }
    }

    .title {
        line-height: 50px;
        text-align: left;
        padding: 0 15px;
        font-weight: 600;
        font-size: 16px;
        border-bottom: 1px solid #e1e3e5;
    }
    .footer {
        height: 70px;
        border-top: 1px solid #e1e3e5;
        display: flex;
        justify-content: center;
        align-items: center;
        position: sticky;
        bottom: 0;
        background-color: #ffffff;
        button {
            margin: 0 5px;
        }
    }
}

.main-content--edit {
    .form-style {
        .inline {
            margin-top: 10px;
            display: flex;
            .Polaris-TextField input {
                width: 40px;
            }
        }
        .list-style--right {
            margin-left: 10px;
            display: flex;
        }
    }
    .list-button {
        display: inline-flex;
        align-items: center;
        list-style: none;
        margin: 0;
        padding: 0px 3px;
        gap: 2px;
        height: 34px;
        li {
            width: 28px;
            height: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border-radius: 6px;
            &.active,
            &:hover {
                background-color: #ffffff;
                box-shadow: 0px 2px 3px 0px #0000001a;
            }
        }
    }
    .form-color {
        label {
            display: block;
            margin-bottom: 5px;
        }
        .btn-select-color {
            width: 36px;
            height: 36px;
            border-radius: 5px;
            cursor: pointer;
        }
    }
    .form-image {
        .inline {
            margin-top: 15px;
        }
    }
    .list-setting--footer {
        .form-group {
            display: flex;
            justify-content: space-between;
            border: 0;
            line-height: 24px;
            .Polaris-Icon {
                height: 1.5rem;
                width: 1.5rem;
            }
            &.hide {
                color: rgba(204, 204, 204, 1);
                span {
                    button {
                        display: none;
                    }
                }
                svg {
                    fill: rgba(204, 204, 204, 1);
                }
            }
        }
    }
    .main-editor {
        * {
            word-wrap: break-word; /* Allow wrapping */
            word-break: break-word; /* Break long words */
            white-space: normal;
        }
        .ql-editor {
            outline: none;
            &::-webkit-scrollbar {
                width: 8px;
            }
            &::-webkit-scrollbar-track {
                background: #fcfaf8;
                border-radius: 4px;
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 4px;
                background: #e3e3e3;
            }
        }
    }
}

.list-banner-image-template__container {
    height: 240px;
    max-width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: #f8f8f8;
    &::-webkit-scrollbar {
        width: 8px;
    }
    &::-webkit-scrollbar-track {
        background: #fcfaf8;
        border-radius: 4px;
        margin: 4px 0;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background: #e3e3e3;
    }
    .list-banner-image-template__wrap {
        height: fit-content;
        margin-right: 4px;
        border-right: 1px solid #ebebeb;
        background-color: #ffffff;
    }
}
.banner-image-template--item {
    width: 100%;
    border-radius: 8px;
    height: auto;
    aspect-ratio: 8.3/1;
    display: block;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.email-schedule {
    &__label {
        color: #797d82;
        display: flex;
        align-items: center;

        .Polaris-Icon {
            margin-left: 5px;
            width: 12px;
            height: 12px;

            svg {
                fill: rgba(92, 95, 98, 0.4);
            }
        }
    }

    .day {
        width: 110px;

        input {
            padding: 0 5px;
        }

        .Polaris-TextField__Spinner {
            width: 15px;
        }
    }

    &--wrapper {
        display: flex;
        align-items: center;
        flex: auto;

        .schedule-col {
            width: 100%;
            max-width: 25%;

            &:last-child {
                text-align: right;
            }
        }
    }

    &.reminder {
        .email-schedule--wrapper {
            justify-content: space-between;

            .schedule-col {
                max-width: 100%;
                display: flex;
                align-items: center;
                &:first-child {
                    display: flex;
                    justify-content: flex-start;
                    gap: 16px;
                }

                &:last-child {
                    text-align: right;
                }
            }
        }
    }
}

.form-list-email {
    margin-bottom: 20px;

    &--wrapper {
        display: flex;
        padding: 8px 12px;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        gap: 10px;
        align-self: stretch;
        border-radius: 4px;
        border: 1px solid var(--border-shadow-border-shadow, #aeb4b9);
        background: var(--surface-default, #fff);
        //box-shadow: 0 1px 0 0 #898F94 inset;
        .Polaris-Tag {
            margin-right: 5px;
        }

        input {
            border: 0 !important;
            outline: 0 !important;
            height: 24px;
        }

        .warning {
            display: flex;
            justify-content: flex-start;
            padding: 10px;

            .Polaris-Icon {
                max-width: 20px;
                width: 100%;
                margin: 0;
                margin-right: 5px;
            }
        }
    }

    label {
        color: #202223;
        font-size: 14px;
    }

    .sub {
        color: #6d7175;
        margin-top: 5px;
    }
}

.queue-send-email {
    background-color: #ffffff;
    border-radius: 8px;
    .fail--container {
        position: relative;
        width: fit-content;
        margin: auto;
        .Polaris-Badge {
            &.Polaris-Badge__toneCritical--strong {
                .Polaris-Text--root {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    align-items: center;
                }
            }
            .Polaris-Text--root.Polaris-Text--bodySm {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: center;
            }
        }
        .fail--message {
            width: fit-content;
            min-width: 136px;
            min-width: 150px;
            position: absolute;
            box-shadow: 0px 8px 20px -4px rgba(23, 24, 24, 0.12);
            padding: 12px;
            border-radius: 4px;
            border: 1px solid #e1e3e1;
            z-index: 99;
            background-color: #f8fdfe;
            font-size: 12px;
            font-weight: 400;
            line-height: 143%;
            letter-spacing: 0.17px;
            top: 100%;
            right: -10%;
            display: none !important;
            cursor: pointer;
        }
        &:hover {
            .fail--message {
                display: block !important;
            }
        }
    }
    .table-queue-send--email {
        height: 100%;
        overflow: scroll;
        max-height: 750px;
        scrollbar-width: none;
        .Polaris-IndexTable {
            .Polaris-IndexTable__TableRow td:first-child {
                padding-top: unset;
                text-align: left;
            }
            .item-table--buyer {
                padding-top: unset;
                text-align: left;
                .item-buyer--wrapper {
                    min-height: 60px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    h6 {
                        text-align: unset !important;
                    }
                }
            }
            padding-left: 0;
            padding-right: 0;
            thead {
                th {
                    text-align: center;
                    &:first-child {
                        text-align: unset;
                    }
                    &:nth-child(2),
                    &:nth-child(3) {
                        text-align: unset !important;
                    }
                    div {
                        justify-content: center;
                    }
                }
            }
            tbody {
                tr:nth-child(6),
                tr:nth-child(7),
                tr:nth-child(8),
                tr:nth-child(9),
                tr:nth-child(10) {
                    .item-product--wrapper {
                        .list-product {
                            bottom: calc(100% + 2px) !important;
                            top: unset;
                            &::before {
                                top: 100%;
                                bottom: unset;
                                transform: translate(-50%, -50%) rotate(-135deg);
                            }
                        }
                    }
                }
                td {
                    text-align: center;
                    & > div {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        h6 {
                            text-align: center;
                        }
                    }
                    &.item-table--location {
                        text-align: left;
                    }
                    &.item-table--action {
                        display: unset;
                        .item-table-action--container {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: nowrap;
                            justify-content: center;
                            gap: 10px;
                            align-items: center;
                        }
                        button {
                            width: fit-content;
                        }
                    }
                }
            }
            td {
                vertical-align: middle;
            }

            .item-table--product {
                .item-product--wrapper {
                    display: flex;
                    flex-wrap: nowrap;
                    flex-direction: row;
                    align-items: center;
                    position: relative;
                    cursor: pointer;
                    margin: auto;
                    .item-count {
                        width: 100%;
                        h6 {
                            white-space: nowrap;
                            display: flex;
                            flex-direction: row;
                            flex-wrap: nowrap;
                            justify-content: space-between;
                            align-items: center;
                            gap: 2px;
                        }
                    }

                    .list-product {
                        display: none;
                        max-width: 250px;
                        position: absolute;
                        text-align: center;
                        background-color: #ffffff;
                        border-radius: 5px;
                        top: calc(100% + 2px);
                        left: 50%;
                        transform: translateX(-50%);
                        box-shadow: 0px 0px 5px #939393;
                        width: 250px;
                        z-index: 50;

                        .product-item {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: nowrap;
                            justify-content: space-between;
                            align-items: center;
                            padding: 5px 10px;
                            border-bottom: 1px solid #e1e3e5;

                            .product-name {
                                text-align: left;
                                overflow: hidden;
                                display: -webkit-box;
                                -webkit-line-clamp: 2;
                                -webkit-box-orient: vertical;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                font-size: 13px;
                                line-height: 20px;
                                max-height: 40px;
                                color: #4385e2;
                                font-weight: 450;
                                text-decoration: none;
                                width: 200px;
                                &:hover {
                                    text-decoration: underline;
                                }
                            }

                            span {
                                width: 30px;
                                font-size: 13px;
                                font-weight: 450;
                                line-height: 20px;
                                color: #303030;
                            }

                            &:first-child {
                                border-bottom: none;
                                border-radius: 5px 5px 0 0;
                            }

                            &:last-child {
                                border-bottom: none;
                                border-radius: 0 0 5px 5px;
                            }
                        }

                        &::before {
                            content: "";
                            display: block;
                            position: absolute;
                            width: 8px;
                            height: 8px;
                            background-color: #ffffff;
                            box-shadow: -2px -2px 2px rgba(204, 204, 204, 1);
                            bottom: 100%;
                            left: 50%;
                            top: 0;
                            transform: rotate(45deg) translateX(-50%);
                        }

                        &::after {
                            content: "";
                            display: block;
                            width: 100%;
                            height: 5px;
                            position: absolute;
                            top: -5px;
                            background-color: transparent;
                        }
                    }

                    &:hover {
                        .list-product {
                            display: block;
                        }
                    }
                }
            }
        }
    }

    .date-picking--filter {
        button {
            min-height: 32px;
        }
    }

    .Polaris-Button {
        .Polaris-Button__Content {
            .Polaris-Button__Text {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;

                .date-custom--ranger {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 20px;
                    color: #000000;
                }
            }
        }
    }
}
.btn-device {
    border: 0;
    opacity: 0.5;
    cursor: pointer;
    background-color: unset;
    &.active {
        opacity: 1;
    }
}
.preview-email.mb {
    .content-preview {
        .email-list-product {
            .email-product--item {
                border-radius: unset;
                flex-direction: column;
                align-items: stretch;
                gap: 10px;
                .call-to-action--btn {
                    width: 100%;
                    max-width: unset;
                    text-align: center;
                }
            }
            .email-product--item {
                .cust-tag {
                    display: inline-block;
                    &:first-child {
                        margin-bottom: 5px;
                    }
                }
            }
        }
        .preview-footer {
            border-width: 2px;
            border-style: solid;
            img {
                width: 30px;
            }
        }
    }
}
.status-done {
    display: flex;
    color: #97a2b3;
    img {
        margin-right: 5px;
    }
}

//email schedule
.modal--list-schedule {
    .email-schedule {
        &--wrapper {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 16px;
            padding: 16px 0;
            &:last-child {
                border: 0;
            }
        }
        &__left {
            width: 100%;
            max-width: 400px;
            .countries {
                .Polaris-InlineStack {
                    gap: 4px;
                }
            }
        }
        &__right {
            display: flex;
            align-items: center;
            gap: 16px;
            .default-input {
                padding: 8px 12px;
                border-radius: 4px;
                background: #e4e5e7;
                border: none;

                text-transform: capitalize;
            }
            .btn-remove {
                border: 0;
                background: none;
                cursor: pointer;
            }
            .countries {
                width: 440px;
                .Polaris-TextField__VerticalContent {
                    overflow: auto;
                }
                .Polaris-Tag {
                    margin-right: 5px;
                    margin-bottom: 5px;
                }
            }
        }
        &--day {
            display: flex;
            align-items: center;

            .day {
                margin: 0;
                display: flex;
                align-items: center;
                width: 110px;
                input {
                    padding: 0 5px;
                }
                .Polaris-Connected {
                    margin-right: 5px;
                }

                .Polaris-TextField__Spinner {
                    width: 15px;
                }
            }
        }
        &__action {
            text-align: center;
        }
    }
}

.option {
    min-width: 320px;
    &.thank-you {
        .Polaris-Select {
            .Polaris-Select__Content {
                display: flex;
                justify-content: space-between;
                .Polaris-Select__SelectedOption {
                    flex: none;
                    font-size: 14px;
                    line-height: 20px;
                    color: #000000;
                }
                .Polaris-Select__Icon {
                    text-align: right;
                }
            }
        }
    }
}

.position-selected-setting--container {
    background-color: #f1f1f1;
    border-radius: 8px;
    padding: 10px 16px;
    height: 100%;
    .Polaris-InlineGrid {
        width: 100%;
        height: 100%;
        .position-selected-setting--item {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            &:nth-child(3n - 2) {
                justify-content: flex-start;
            }
            &:nth-child(3n - 1) {
                justify-content: center;
            }
            &:nth-child(3n) {
                justify-content: flex-end;
            }
            .position-selected-setting--item__icon {
                width: 20px;
                height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                .unselected {
                    display: block;
                    width: 8px;
                    height: 8px;
                    background-color: #cccccc;
                    border-radius: 50%;
                }
            }
        }
    }
}

.countries-selected__item{
    &:hover{
        background-color: var(--p-color-bg-surface-secondary-active);
    }
    .countries-selected__item-checkbox{
        width: fit-content;
    }
}

.countries-selected--flag__icon {
    width: 32px;
    height: 24px;
    border-radius: 4px;
}

.countries-selector--container{
    >.Polaris-Box{
        cursor: pointer;
        &:first-child{
            border-radius: 4px 4px 0 0 ;
            overflow: hidden;
        }
        &:last-child{
            border-radius: 0 0 4px 4px ;
            overflow: hidden;
        }
    }
}