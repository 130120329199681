.group-product--container {
    .group-item {
        cursor: pointer;
        position: relative;
        &:hover {
            background-color: #f7f7f7;
            .group-item--name {
                text-decoration: underline;
            }
        }
        .group-product_list-product {
            display: block;
            position: absolute;
            width: 342px;
            top: 12px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 99;
            &.show-above {
                top: unset;
                bottom: 12px;
            }
            .group-product_list-product--img {
                position: relative;
                border-radius: 12px;
                &.last {
                    &::before {
                        content: "";
                        position: absolute;
                        top: 0;
                        right: 0;
                        left: 0;
                        bottom: 0;
                        background-color: rgba(0, 0, 0, 0.5);
                        z-index: 9;
                    }
                }
            }
        }
    }

    .create-group-product__modal--container {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 999;
        display: flex;
        justify-content: center;
        align-items: center;
        .create-group-product_modal--wrap {
            width: 90%;
            max-width: 620px;
            border-radius: 12px;
            overflow: hidden;
            background-color: #ffffff;
            height: 90vh;
            .Polaris-IndexTable__SortableTableHeadingWithCustomMarkup {
                display: block;
            }
        }
    }

    .group-detail__list-reviews--container {
        .tab-item {
            cursor: pointer;
            background-color: #dfdfdf;
            &.tab-active,
            &:hover {
                background-color: var(--p-color-bg-fill-transparent-hover);
                color: var(--p-color-text-brand);
            }
        }
    }

    .limit-function--tooltip {
        position: relative;
        width: fit-content;
        &:hover {
            .limit-function-tooltip--content {
                display: block;
            }
        }
        .limit-function-tooltip--content {
            display: block;
            position: absolute;
            top: calc(100% + 12px);
            width: 200%;
            left: 50%;
            transform: translateX(-50%);
            background-color: #ffffff;
            padding: 4px 8px;
            border-radius: 4px;
            filter: drop-shadow(rgba(0, 0, 0, 0.3) 0 2px 3px);
            z-index: 999;
            &::before {
                content: "";
                position: absolute;
                top: -16px;
                left: 50%;
                transform: translateX(-50%);
                border-width: 8px;
                border-style: solid;
                border-color: transparent transparent #ffffff transparent;
            }
            &::after {
                content: "";
                position: absolute;
                top: -12px;
                width: 100%;
                height: 12px;
            }
            &.disable {
                display: none !important;
            }
        }
    }
}
